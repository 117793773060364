import React, { useState } from "react";
import Modal from "react-modal";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

//Modal Setting
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",

    minheight: "450px",
    transform: "translate(-50%, -50%)",
  },
};

const customStylesAvatar = {};
Modal.setAppElement(document.getElementById("root"));

const QuizModal = (props) => {
  const saveQuestion = () => {
    return fetch("https://backend.principshop.com/quiz/addQuestion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: props.questionId,
        question: props.questionData.question,
        answer1: props.questionData.answer1,
        answer2: props.questionData.answer2,
        answer3: props.questionData.answer3,
        answer4: props.questionData.answer4,
        correctAnswer: props.questionData.correctAnswer,
        points: props.questionData.points,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Creating or editing a post failed!");
        }
        props.closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnChange = (event) => {
    console.log(props.questionData);
    const { name, value } = event.target;
    props.setQuestionsData({ ...props.questionData, [name]: value });
  };
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <h2>Dodaj pitanje</h2>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Pitanje "
        name="question"
        value={props.questionData.question}
        autoFocus
        onChange={handleOnChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Odgovor 1 "
        value={props.questionData.answer1}
        name="answer1"
        autoFocus
        onChange={handleOnChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Odgovor 2 "
        value={props.questionData.answer2}
        name="answer2"
        autoFocus
        onChange={handleOnChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Odgovor 3 "
        value={props.questionData.answer3}
        name="answer3"
        autoFocus
        onChange={handleOnChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Odgovor 4 "
        value={props.questionData.answer4}
        name="answer4"
        autoFocus
        onChange={handleOnChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        label="Tacan odgovor [1,2,3,4]"
        value={props.questionData.correctAnswer}
        name="correctAnswer"
        autoFocus
        onChange={handleOnChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        label="Poeni"
        value={props.questionData.points}
        label="Poeni"
        name="points"
        autoFocus
        onChange={handleOnChange}
      />
      <div className="modalButton">
        <Button
          style={{ marginRight: "5px", width: "80px" }}
          className="btn-round"
          color="danger"
          onClick={props.closeModal}
        >
          Zatvori
        </Button>
        <Button
          style={{ width: "80px" }}
          className="btn-round"
          color="success"
          onClick={saveQuestion}
        >
          Sacuvaj
        </Button>
      </div>
    </Modal>
  );
};

export default QuizModal;
