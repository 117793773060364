import React, { useState } from "react";
import MaterialTable from "material-table";

//table icons
import tableIcons from "../../assets/table";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Update from "@material-ui/icons/Update";
import Accessibility from "@material-ui/icons/Accessibility";
import CheckIcon from "@material-ui/icons/Check";
import CachedIcon from "@material-ui/icons/Cached";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const [timeUpdate, setTimeUpdate] = useState("");
  const [adminData, setAdminData] = useState([]);
  const classes = useStyles();

  React.useEffect(() => {
    fetch("https://backend.principshop.com/admin/getAll", {
      method: "GET",
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Error creating User");
        }
        return res.json();
      })
      .then((resData) => {
        var array = [];
        resData.data.forEach((element) => {
          var email = element.email;
          var id = element.id;
          var password = "**********";
          var obj = {
            email,
            id,
            password,
          };
          array.push(obj);
        });
        setAdminData(array);
      });
  }, []);

  const createAdmin = (data) => {
    fetch("https://backend.principshop.com/admin/addNewAdmin", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data.email,
        password: data.password,
      }),
    })
      .then((res) => {
        console.log(res);
        if (res.status !== 200) {
          throw new Error("Error creating User");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateAdmin = (data) => {
    console.log(data.password);
    fetch("https://backend.principshop.com/admin/updateAdmin", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: data.id,
        email: data.email,
        password: data.password,
      }),
    })
      .then((res) => {
        console.log(res);
        if (res.status !== 200) {
          throw new Error("Error creating User");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteAdmin = (id) => {
    fetch("https://backend.principshop.com/admin/deleteAdmin", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => {
        console.log(res);
        if (res.status !== 200) {
          throw new Error("Error creating User");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div style={{ marginTop: "80px", minHeight: "100vh" }}>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <CachedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Api calls</p>
              <h3 className={classes.cardTitle}>0</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                {timeUpdate}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <CheckIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Accepted</p>
              <h3 className={classes.cardTitle}>0</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                {timeUpdate}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Declined</p>
              <h3 className={classes.cardTitle}>0</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                {timeUpdate}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Number of admins</p>
              <h3 className={classes.cardTitle}>{adminData.length}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                {timeUpdate}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Employees Stats</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["ID", "Name", "Title"]}
                tableData={[["1", "Dimitrije Tosic", "CEO"]]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: "Email", field: "email" },
          { title: "Password", field: "password" },
        ]}
        data={adminData}
        editable={{
          onRowAdd: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                createAdmin(newData);
                var array = [...adminData];
                var index = array.indexOf(oldData);
                array[index] = newData;
                array[index].password = "**********";

                setAdminData([...array, newData]);

                resolve();
              }, 1000);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              updateAdmin(newData);
              var array = [...adminData];
              var index = array.indexOf(oldData);
              array[index] = newData;
              array[index].password = "**********";
              setAdminData(array);
              resolve();
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              console.log(oldData.id);
              deleteAdmin(oldData.id);
              var array = [...adminData]; // make a separate copy of the array
              var index = array.indexOf(oldData);

              if (index !== -1) {
                array.splice(index, 1);
                setAdminData(array);
              }
              resolve();
            }),
        }}
        options={{
          pageSize: 5,
          paging: true,
        }}
        localization={{
          pagination: {
            labelRowsPerPage: "6",
          },
        }}
        title="Admins"
      />
    </div>
  );
}
