import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
//table icons
import tableIcons from "../../assets/table";
import { Button, Form, Input, Row, Col, Container } from "reactstrap";
//Table
import PlaceIcon from "@material-ui/icons/Place";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import Geocode from "react-geocode";
import Update from "@material-ui/icons/Update";
import Accessibility from "@material-ui/icons/Accessibility";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { string } from "prop-types";
import FixedPlugin from "components/FixedPlugin/FixedPlugin";
const useStyles = makeStyles(styles);
var QRCode = require("qrcode.react");

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyB82_TQpWmfEU0cLfiCFyEnH30qYv9BuMc&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap defaultZoom={8} defaultCenter={{ lat: 44.787197, lng: 20.457273 }}>
    {props.location.map((marker) => {
      console.log(marker.latitude, marker.longitude);
      return (
        <Marker
          position={{
            lat: parseFloat(marker.latitude),
            lng: parseFloat(marker.longitude),
          }}
          title={marker.name}
        />
      );
    })}
  </GoogleMap>
));

export default function Orders() {
  const [timeUpdate, setTimeUpdate] = useState("");
  const [location, setLocations] = useState([]);
  const [address, setAddress] = useState([]);
  const [searchedAddress, setSearchedAddress] = useState({
    address: string,
    longitude: string,
    latitute: string,
  });
  const [showAddress, setShowAddress] = useState(false);
  const classes = useStyles();

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setAddress(address);
        setShowAddress(true);
        setSearchedAddress({
          address: address,
          latitute: latLng.lat,
          longitude: latLng.lng,
        });
        console.log("Success", latLng);
      })
      .catch((error) => console.error("Error", error));
  };

  React.useEffect(() => {
    fetch("https://backend.principshop.com/location/getAll", {
      method: "GET",
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Error creating User");
        }
        return res.json();
      })
      .then((resData) => {
        var array = [];
        resData.data.forEach((data) => {
          var obj = {
            name: data.name,
            description: data.description,
            address: data.address,
            longitude: data.longitude,
            latitude: data.latitude,
            points: data.points,
            time: data.time,
          };
          array.push(obj);
        });
        setLocations(array);
      });
  }, []);

  const addLocation = (data) => {
    fetch("http://backend.principshop.com/location/addNewLocation", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: data.name,
        description: data.description,
        address: data.address,
        longitude: data.longitude,
        latitude: data.latitude,
        points: data.points,
        time: data.time,
      }),
    })
      .then((res) => {
        console.log(res);
        if (res.status !== 200) {
          throw new Error("Error creating User");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteAdmin = (address) => {
    fetch("https://backend.principshop.com/location/deleteLocation", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        address: address,
      }),
    })
      .then((res) => {
        console.log(res);
        if (res.status !== 200) {
          throw new Error("Error creating User");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateLocation = (data) => {
    fetch("https://backend.principshop.com/location/updateLocation", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: data.name,
        description: data.description,
        address: data.address,
        longitude: data.longitude,
        latitude: data.latitude,
        points: data.points,
        time: data.time,
      }),
    })
      .then((res) => {
        console.log(res);
        if (res.status !== 200) {
          throw new Error("Error creating User");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="userTable">
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <PlaceIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total locations</p>
              <h3 className={classes.cardTitle}>{location.length}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                {timeUpdate}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <MyMapComponent location={location}></MyMapComponent>

      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
        {showAddress ? (
          <div>
            <p className="autoText">Address: {searchedAddress.address}</p>
            <p className="autoText">longitude:{searchedAddress.longitude}</p>
            <p className="autoText">Latitude:{searchedAddress.latitute}</p>
          </div>
        ) : null}
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
          className="autocompleteInput"
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: "Search Places ...",
                  className: "autocompleteInput",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>

      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: "Naziv", field: "name", width: "100px" },
          { title: "Opis", field: "description", width: "600px" },
          { title: "Adresa", field: "address", width: "100px" },
          { title: "Points", field: "points", width: "100px" },
          { title: "Time", field: "time", width: "100px" },
          { title: "Longitude", field: "longitude", width: "100px" },
          { title: "Latitude", field: "latitude", width: "100px" },
        ]}
        data={location}
        editable={{
          onRowAdd: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                addLocation(newData);
                var array = [...location];
                array.unshift(newData);

                setLocations([...array, newData]);

                resolve();
              }, 1000);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              updateLocation(newData);
              var array = [...location];
              var index = array.indexOf(oldData);
              array[index] = newData;

              setLocations(array);
              resolve();
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              deleteAdmin(oldData.address);
              var array = [...location]; // make a separate copy of the array
              var index = array.indexOf(oldData);

              if (index !== -1) {
                array.splice(index, 1);
                setLocations(array);
              }
              resolve();
            }),
        }}
        options={{
          pageSize: 5,
          paging: true,
          tableLayout: "fixed",
          addRowPosition: "first",
        }}
        localization={{
          pagination: {
            labelRowsPerPage: "6",
          },
        }}
        title="Locations"
      />
    </div>
  );
}
