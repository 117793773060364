import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import QuizModal from "./quizModal";
import MaterialTable from "material-table";
import tableIcons from "../../assets/table";
export default function Quiz() {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [questionId, setQuestionId] = React.useState("");
  const [questions, setQuestions] = useState([]);
  const [questionData, setQuestionsData] = useState({
    question: "",
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    correctAnswer: "",
    points: "",
  });
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    getAllQuestions();
  };

  const addQuestion = () => {
    setQuestionId(-1);
    setQuestionsData({
      question: "",
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      correctAnswer: "",
      points: "",
    });
    openModal();
  };
  const getAllQuestions = () => {
    fetch("https://backend.principshop.com/quiz/getAll", {
      method: "GET",
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Error creating User");
        }
        return res.json();
      })
      .then((resData) => {
        var array = [];
        resData.data.forEach((data) => {
          var obj = {
            id: data.id,
            question: data.question,
          };
          array.push(obj);
        });
        console.log(array);
        setQuestions(array);
      });
  };
  const getQuestionData = (id) => {
    fetch("https://backend.principshop.com/quiz/getById", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Error creating User");
        }
        return res.json();
      })
      .then((resData) => {
        setQuestionsData({
          question: resData.data.question,
          answer1: resData.data.answer1,
          answer2: resData.data.answer2,
          answer3: resData.data.answer3,
          answer4: resData.data.answer4,
          correctAnswer: resData.data.correctAnswer,
          points: resData.data.points,
        });
        console.log(resData.data);
      });
  };
  const deleteQuestion = (id) => {
    fetch("https://backend.principshop.com/quiz/deleteQuestion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Error creating User");
        }
        return res.json();
      })
      .then((resData) => {
        getAllQuestions();
      });
  };
  React.useEffect(() => {
    getAllQuestions();
  }, []);
  return (
    <div>
      <QuizModal
        questionId={questionId}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        questionData={questionData}
        setQuestionsData={setQuestionsData}
      />
      <div className="quiz">
        <h2 style={{ marginBottom: "0px", textAlign: "center" }}>
          Lista pitanja
        </h2>
        <Button color="success" onClick={addQuestion}>
          {" "}
          Dodaj pitanje
        </Button>

        <MaterialTable
          icons={tableIcons}
          columns={[
            { title: "Id", field: "id", width: "20px" },
            { title: "Pitanje", field: "question", width: "100px" },
          ]}
          data={questions}
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                deleteQuestion(oldData.id);
                resolve();
              }),
          }}
          actions={[
            {
              icon: "save",
              tooltip: "Save User",
              onClick: (event, rowData) => {
                getQuestionData(rowData.id);
                setQuestionId(rowData.id);
                openModal();
              },
            },
          ]}
          options={{
            pageSize: 5,
            paging: true,
            tableLayout: "fixed",
            addRowPosition: "first",
          }}
          localization={{
            pagination: {
              labelRowsPerPage: "6",
            },
          }}
          title="Locations"
        />
      </div>
    </div>
  );
}
