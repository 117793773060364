import React, { useState } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import AdminDashBoard from "layouts/AdminDashBoard.js";
import AdminUsers from "layouts/AdminUsers.js";
import AdminLocation from "layouts/AdminLocation";
import AdminLogin from "views/AdminLogin/AdminLogin";
import AdminQuiz from "layouts/AdminQuiz";
const AppRoutes = (props) => {
  const [isAutenticated, setIsAuthenticated] = useState("");
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");

  const authenticateUser = (token, id) => {
    setIsAuthenticated(true);
    setToken(token);
    setUserId(id);
  };
  const logOut = () => {
    console.log("logout");
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    setIsAuthenticated(false);
    setToken("");
    setUserId("");
  };
  return (
    <div>
      <Switch>
        <Route
          path="/"
          render={(props) => (
            <AdminLogin {...props} authenticateUser={authenticateUser} />
          )}
          exact
        />
        <Route
          path="/admin/dashboard"
          render={(props) =>
            localStorage.getItem("adminId") ? (
              <AdminDashBoard
                {...props}
                logout={logOut}
                token={token}
                id={userId}
              />
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route
          path="/admin/notification"
          render={(props) =>
            localStorage.getItem("adminId") ? (
              <AdminUsers {...props} logout={logOut} token={token} />
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route
          path="/admin/location"
          render={(props) =>
            localStorage.getItem("adminId") ? (
              <AdminLocation {...props} logout={logOut} token={token} />
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route
          path="/admin/quiz"
          render={(props) =>
            localStorage.getItem("adminId") ? (
              <AdminQuiz {...props} logout={logOut} token={token} />
            ) : (
              <AdminQuiz {...props} logout={logOut} token={token} />
            )
          }
        />
      </Switch>
    </div>
  );
};

export default withRouter(AppRoutes);
