import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import card from "../../assets/img/card.jpg";
//Table
import tableIcons from "../../assets/table";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Icon from "@material-ui/core/Icon";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CheckIcon from "@material-ui/icons/Check";
import CachedIcon from "@material-ui/icons/Cached";
import Update from "@material-ui/icons/Update";
import Accessibility from "@material-ui/icons/Accessibility";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);
var QRCode = require("qrcode.react");

export default function UserTable() {
  const classes = useStyles();
  const [timeUpdate, setTimeUpdate] = useState("");
  const [data, setData] = useState([]);
  const [dataAdmin, setDataAdmin] = useState([]);
  const [qrValue, setQrValue] = useState("");
  const [id, setId] = useState("");

  const [state, setState] = useState({
    title: "",
    subtitle: "",
    data: "",
    url: "",
  });

  const sendNotification = (event) => {
    event.preventDefault();
    fetch("https://onesignal.com/api/v1/notifications", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic         YThjOGE5ZmItNWViYy00M2IzLWI0YzUtNTk2ZGZlNjI2NDk5",
      },
      body: JSON.stringify({
        app_id: "e44286ab-27b4-468a-affe-c5d3688c89e6",
        included_segments: ["All"],
        url: state.url,
        headings: { en: state.title },
        contents: { en: state.subtitle },
        subtitle: { en: state.data },
      }),
    })
      .then((res) => {
        if (res.status === 401) {
          throw new Error("Email or password incorect");
        }
        if (res.status !== 200) {
          throw new Error("Techical error");
        }
        return res.json();
      })
      .then((resData) => {
        setState({
          title: "",
          subtitle: "",
          url: "",
          data: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOnChange = (event) => {
    console.log("test");
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };
  return (
    <div className="userTable">
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>event_available</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Number of notification</p>
              <h3 className={classes.cardTitle}>{data.length}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                {timeUpdate}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <CheckIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Number of success</p>
              <h3 className={classes.cardTitle}>{dataAdmin.length}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                {timeUpdate}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>
                Number of failed notification
              </p>
              <h3 className={classes.cardTitle}>{dataAdmin.length}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                {timeUpdate}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <div style={{ width: "40%" }}>
        <h2 style={{ marginBottom: "0px" }}>Push notification</h2>
        <div className="row" style={{ paddingTop: "2px" }}>
          <CustomInput
            id="title"
            inputProps={{
              placeholder: "Title",
              name: "title",
              value: state.title,
            }}
            onChange={handleOnChange}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </div>
        <div className="row">
          <CustomInput
            id="subtitle"
            inputProps={{
              placeholder: "Subtitle",
              name: "subtitle",
              value: state.subtitle,
            }}
            onChange={handleOnChange}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </div>
        <div className="row">
          <CustomInput
            id="data"
            inputProps={{
              placeholder: "Data",
              name: "data",
              value: state.data,
            }}
            onChange={handleOnChange}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </div>
        <div className="row">
          <CustomInput
            id="url"
            inputProps={{
              placeholder: "Url",
              name: "url",
              value: state.url,
            }}
            onChange={handleOnChange}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </div>
        <Button color="success" type="submit" onClick={sendNotification}>
          Send
        </Button>
      </div>
    </div>
  );
}
