import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import NotificationsIcon from "@material-ui/icons/Notifications";
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    layout: "/admin",
  },

  {
    path: "/location",
    name: "Location",
    icon: AddLocationIcon,
    layout: "/admin",
  },
  {
    path: "/notification",
    name: "Notification",
    icon: NotificationsIcon,
    layout: "/admin",
  },
  {
    path: "/quiz",
    name: "Quiz",
    icon: NotificationsIcon,
    layout: "/admin",
  },
  {
    path: "",
    name: "Logout",
    icon: "",
    layout: "/",
  },
];

export default dashboardRoutes;
